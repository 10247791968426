import React from 'react'
import { DiscussionEmbed } from 'disqus-react'
import { Typography, Paper } from '@mui/material'

function DiscussComment ({ slug, title, shortname }) {
  return (
    <Paper elevation={8} sx={{ padding: 4, my: 4 }}>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Comments
      </Typography>
      <DiscussionEmbed shortname={shortname} config={{ identifier: slug, title }} />
    </Paper>
  )
}

export default DiscussComment
