import React from 'react';
import { CatIcon, DateIcon, ReadIcon } from '../components/Icons';
import { Paper, Typography, Grid, Divider, Button } from '@mui/material';
import Seo from 'gatsby-plugin-wpgraphql-seo';

import Layout from '../layout';
import { formatDate, styles } from '../utils';
import CategoryTag from '../components/CategoryTag';
import DiscussComment from '../components/DiscussComment';
import setUpShortcode from '../assets/wp_plugins/js/shortcodes-ultimate';
import config from '../config/default';

setUpShortcode();

const Post = ({ pageContext }) => {
  const { title, content, date, categories, slug, seo } = pageContext;

  return (
    <Layout>
      <Seo post={pageContext} />
      <Paper elevation={8} sx={{ padding: 4 }}>
        <Typography variant='h1' sx={styles.singlePostTitle}>
          {title}
        </Typography>
        <Grid container>
          <Grid item sx={styles.flexCenter}>
            <DateIcon />
            <Typography
              variant='caption'
              sx={{
                display: 'inline-block',
                mr: 1,
              }}
            >
              {formatDate(date)}
            </Typography>
          </Grid>
          <Grid item sx={styles.flexCenter}>
            <CatIcon />
            <Grid container spacing={1} sx={styles.flexCenter}>
              {categories.nodes.map((c, i) => {
                return <CategoryTag name={c.name} key={i} slug={c.slug} />;
              })}
            </Grid>
          </Grid>
          <Grid item sx={styles.flexCenter}>
            <ReadIcon />
            <Typography variant='caption'>{`${seo.readingTime} Min Read`}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container>
          <Grid item xs={12} sx={styles.flexCenter}>
<a target={'_blank'} href={config.ppiADS.replace('{word}', title)} >

        <Button  variant='outlined' sx={{mt: 4, mb: 4}} >Download full version now</Button>
</a>
          </Grid>
        </Grid>
        <Typography variant='body1' component='div' dangerouslySetInnerHTML={{ __html: content }} />
        <Grid container>
          <Grid item xs={12} sx={styles.flexCenter}>
            <a target={'_blank'} href={config.ppiADS.replace('{word}', title)} >


            <Button  variant='outlined' sx={{mt: 4, mb: 4}} >Download full version now</Button>
            </a>
          </Grid>
        </Grid>
      </Paper>
      <DiscussComment shortname='https-apksoup-com' slug={slug} title={title} />
    </Layout>
  );
};

export default Post;
